import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Content, Text, Form } from './styles.js'
import * as emailjs from 'emailjs-com'

import Button from '@atoms/Button'
import Checkbox from '@atoms/Checkbox'
import HorWrap from '@atoms/HorWrap/index.js'
import Input from '@atoms/Input'
import ReactMarkdown from 'react-markdown'
import Toast from '@atoms/Toast/index.js'

const ContactForm = ({ mobileText, desktopText }) => {
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    user_company: '',
    user_phone: '',
    user_msg: '',
  })
  const [formSent, setFormSent] = useState(false)
  const [formSendSucces, setFormSendSucces] = useState(false)
  const [formSendError, setFormSendError] = useState(false)

  const handleUpdateField = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setFormSent(true)

    const setWindowDataLayer = () => {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'form_submitted',
        form_name: 'contact',
      })
    }

    typeof window !== 'undefined' && setWindowDataLayer()

    emailjs
      .sendForm(
        'AAM9xmLsVvsCjauKUAEGvKgk',
        'mola',
        '#contactForm',
        'user_eSllWp8flAh2sbHdCeaw8'
      )
      .then(
        response => {
          setFormSendSucces(true)
        },
        err => {
          setFormSendError(true)
        }
      )
  }

  return (
    <Wrapper>
      <HorWrap>
        <Content>
          <Text>
            <span>{mobileText}</span>
            <span>
              <ReactMarkdown children={desktopText} />
            </span>
          </Text>
          <Form id="contactForm" onSubmit={e => handleSubmit(e)}>
            <div>
              <Input
                required
                type="text"
                name="user_name"
                label="Your name"
                value={formData.user_name}
                onChangeEvent={e => handleUpdateField(e)}
              />
              <Input
                required
                type="email"
                name="user_email"
                label="Your work email"
                value={formData.user_email}
                onChangeEvent={e => handleUpdateField(e)}
              />
              <Input
                type="text"
                name="user_company"
                label="Your business (optional)"
                value={formData.user_company}
                onChangeEvent={e => handleUpdateField(e)}
              />
              <Input
                type="number"
                name="user_phone"
                label="Your phone (optional)"
                value={formData.user_phone}
                onChangeEvent={e => handleUpdateField(e)}
              />
              <Input
                required
                type="textarea"
                name="user_msg"
                label="Your message"
                value={formData.user_msg}
                onChangeEvent={e => handleUpdateField(e)}
              />
              <br />
              <Checkbox noCheckbox />
              <Button primary smallPaddings>
                Send
              </Button>
            </div>
          </Form>
          {formSent && formSendSucces === true && (
            <Toast
              type="success"
              title="Thank you for your message"
              description="We will get back to you shortly."
            />
          )}
          {formSent && formSendError === true && (
            <Toast
              type="error"
              title="Something went wrong"
              description="Please, try again later."
            />
          )}
        </Content>
      </HorWrap>
    </Wrapper>
  )
}

ContactForm.propTypes = {
  active: PropTypes.bool,
}

export default ContactForm
