import styled from 'styled-components'

export const Wrapper = styled.section`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 2400px;
  background: ${({ theme }) => theme.colors.grey3};
  color: ${({ theme }) => theme.colors.grey};

  ${({ theme }) => theme.media.m} {
    justify-content: flex-start;
  }
`

export const Content = styled.div`
  width: 100%;
  max-width: 1920px;
  padding: 50px 0;
  font-size: 18px;
  font-weight: 600;

  ${({ theme }) => theme.media.s} {
    padding: 80px 0;
    font-size: 12px;
  }

  ${({ theme }) => theme.media.l} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    padding: 90px 0;
    font-size: 14px;
  }

  ${({ theme }) => theme.media.xl} {
    padding: 100px 0;
  }

  ${({ theme }) => theme.media.xxl} {
    padding: 120px 0;
  }
`

export const Text = styled.h3`
  margin: 0 0 20px;
  font-size: 40px;
  font-weight: 900;
  color: ${({ theme }) => theme.colors.purple};

  span:nth-child(2) {
    display: none;
  }

  ${({ theme }) => theme.media.s} {
    font-size: 30px;
  }

  ${({ theme }) => theme.media.m} {
    margin: 0;
    font-size: 36px;
  }

  ${({ theme }) => theme.media.l} {
    font-size: 42px;
    span:nth-child(1) {
      display: none;
    }
    span:nth-child(2) {
      display: block;
    }
  }

  ${({ theme }) => theme.media.xl} {
    font-size: 48px;
  }

  ${({ theme }) => theme.media.xxl} {
    font-size: 58px;
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div > * {
    margin: 0 0 30px;
  }

  ${({ theme }) => theme.media.m} {
    align-items: flex-start;

    > div > * {
      margin: 0 30px 30px 0;
    }
  }
`
